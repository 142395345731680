<template>
	<div class="swiper-box">
		<!-- <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
			<swiper-slide v-for="imageInfo in attachlist" :key="imageInfo.sdra_fileid">
				<div class="imagewarp">
					<img :src="baseUrlpath + imageInfo.sdra_filePath" @error="imgOnError" />
				</div>
			</swiper-slide>
			<div class="swiper-button-next" slot="button-next"></div>
			<div class="swiper-button-prev" slot="button-prev"></div>
		</swiper>
		<swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
			<swiper-slide v-for="imageInfo in attachlist" :key="imageInfo.sdra_fileid">
				<div class="imagewarp">
					<img :src="baseUrlpath + imageInfo.sdra_filePath" @error="imgOnError" />
				</div>
			</swiper-slide>
		</swiper> -->
		<el-carousel  height="420px" :interval="4000" direction="vertical">
		    <el-carousel-item v-for="imageInfo in attachlist" :key="item">
				<div class="imagewarp">
					<img :src="baseUrlpath + imageInfo.sdra_filePath" @error="imgOnError" />
				</div>
		    </el-carousel-item>
		  </el-carousel>
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from 'vue-awesome-swiper'
	import 'swiper/dist/css/swiper.css'
	export default {
		components: {
			swiper,
			swiperSlide
		},
		props: {
			attachlist: {
				type: Array,
				required: true
			},
			baseUrlpath: {
				type: String,
				required: true
			}
		},
		data() {
			return {
				defalutImg: require('@/assets/icon/noimg.jpg'),
				swiperOptionTop: {
					loop: true,
					loopedSlides: 8, // looped slides should be the same
					spaceBetween: 10,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					}
				},
				swiperOptionThumbs: {
					loop: true,
					loopedSlides: 8, // looped slides should be the same
					spaceBetween: 10,
					centeredSlides: true,
					slidesPerView: 'auto',
					touchRatio: 0.2,
					slideToClickedSlide: true
				}
			}
		},
		mounted() {
			console.log(this.attachlist);
			this.$nextTick(() => {
				console.log(this.$refs)
				console.log(this.$refs.swiperTop)
				const swiperTop = this.$refs.swiperTop.swiper
				const swiperThumbs = this.$refs.swiperThumbs.swiper

				swiperTop.controller.control = swiperThumbs
				swiperThumbs.controller.control = swiperTop
			})
		},
		methods: {
			imgOnError(e) {
				const img = e.srcElement;
				img.src = this.defalutImg;
				img.onerror = null
			}
		}
	}
</script>

<style scoped lang="scss">
	.swiper-box {
		/* height: 600px; */

		.imagewarp {
			width: 100%;
			height: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

	swiper-slide {
		img {
			width: 100%;
			height: 100%;
		}
	}

	.swiper-slide {
		background-size: contain;
		background-position: center;
	}

	.gallery-top {
		height: 80% !important;
		width: 100%;
	}

	.gallery-thumbs {
		height: 20% !important;
		box-sizing: border-box;
		padding: 10px 0;
	}

	.gallery-thumbs .swiper-slide {
		width: 25%;
		height: 100%;
		opacity: 0.4;

	}

	.gallery-thumbs .swiper-slide-active {
		opacity: 1;
	}
</style>
