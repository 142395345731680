<template>
	<div class="coms-itm-warp" @click="goDetail()">
		<div class="atta-img">
			<template v-if="item.attachlist.length > 0">
				<div v-if="$util.isVideo(item.attachlist[0].sdra_filePath)" class="att-warp">
					<video :src="item.attachlist[0].sdra_filePath" controls="controls" loop="loop" @click.stop=""
						 :id="item.attachlist[0].sdra_fileid" @play="play(item.attachlist[0].sdra_fileid)"></video>
				</div>
				<div v-else class="att-warp">
					<el-image fit="cover" lazy :src="baseUrlpath + item.attachlist[0].sdra_filePath"></el-image>
				</div>
			</template>
			<template v-else>
				<el-image fit="cover" lazy :src="defalutImage" ></el-image>
			</template>

			<div class="addr">
				<div class="flex-start">
					<div class="icon"></div>
					<div class="txt_left">{{item.sdrr_recommendPlace}}</div>
				</div>
			</div>
		</div>
		<div class="com-desc">
			{{$util.mySubStr($util.filterHtml(item.sdrr_contents),30)}}
		</div>
	</div>
</template>

<script>
	export default {
		name: 'commendReq',
		props: {
			item: {
				type: Object,
				required: true
			},
			baseUrlpath: {
				type: String,
				required: true
			}
		},
		data() {
			return {
				defalutImage: require('../assets/icon/noimg.jpg')
			}
		},
		created() {
			// console.log(this.item);
		},
		methods: {
			goDetail() {
				this.$router.push({
					name: '/recommend/detail',
					query: {
						reqNo: this.item.sdrr_reqId
					}
				});
			},
			play(fileId){
				const videoElements = document.getElementsByTagName('video');
				console.log('页面的video数量====>'+videoElements.length)
				if (videoElements && videoElements.length > 0) {
					for (let i = 0; i < videoElements.length; i++) {
						if (fileId === videoElements[i].id) {
							videoElements[i].play()
						} else {
							videoElements[i].pause()
						}
					}
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	/* .coms-itm-warp:hover {
		transform:scale(1.05);
		transition:transform 0.45s ease;
	} */
	.addr {
		position: absolute;
		bottom: 0;
		color: #fff;
		background-color: rgba(37, 35, 35, 0.5803921569);
		padding: 2px 6px 2px 2px;
		border-radius: 4px;
		font-size: 16px;

		.icon {
			background: url(../assets/icon/addr-empty.png) no-repeat center;
			background-size: 100%;
			width: 16px;
			height: 16px;
			margin-right: 0px;
		}
	}

	.coms-itm-warp {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		height: 100%;
		margin-bottom: 10px;
		cursor: pointer;
	}

	.coms-itm-warp .itm-place {
		color: #fff;
		position: absolute;
		bottom: 4px;
		left: 4px;
		font-size: 10px;
		text-align: left;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.coms-itm-warp .itm-place .itm-place-icon {
		background: url(../assets/icon/addr-empty.png) no-repeat center;
		background-size: 100%;
		width: 14px;
		height: 14px;
	}

	.coms-itm-warp .com-desc {
		text-align: left;
		color: #666666;
		font-size: 18px;
		font-weight: normal;
		padding: 10px 4px;
	}

	.coms-itm-warp .att-warp {
		width: 100%;
		height: 175px;
		overflow: hidden;
		position: relative;
		border-radius: 6px;
		video {
			width: 100%;
			height: 100%;
		}
	}

	.coms-itm-warp .atta-img {
		position: relative;
	}

	.coms-itm-warp .atta-img .el-image {
		height: 100%;
		width: 100%;
	}
</style>
