<template>
	<div id="app">
		<div class="hp_header">
			<div class="leftSideNavLayer">
				<div class="lsn_left_nav_container">
					<div class="lsn_small_left_nav_container">
						<div class="lsn_top_button_wrap" @click="changeCollapse">
							<div class="lsn_top_button">
								<div class="pc_home-tabbtnIcon">
									<svg t="1660015706499" class="icon" viewBox="0 0 1024 1024" version="1.1"
										xmlns="http://www.w3.org/2000/svg" p-id="3022" width="22" height="22">
										<path
											d="M96 160h832c19.2 0 32 12.8 32 32s-12.8 32-32 32h-832c-19.2 0-32-12.8-32-32s12.8-32 32-32z m0 320h832c19.2 0 32 12.8 32 32s-12.8 32-32 32h-832c-19.2 0-32-12.8-32-32s12.8-32 32-32z m0 320h832c19.2 0 32 12.8 32 32s-12.8 32-32 32h-832c-19.2 0-32-12.8-32-32s12.8-32 32-32z"
											p-id="3023" fill="#2c2c2c"></path>
									</svg>
								</div>
							</div>
						</div>
						<el-menu :default-active="menuIndexstr" active-text-color="#c88854"
							class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
							:collapse="isCollapse">
							<el-menu-item index="main" @click="goToDetail('main')">
								<i class="el-icon-main"></i>
								<span slot="title">{{$t('basic.home')}}</span>
							</el-menu-item>
							<template v-if="!isWorkCenter">
								<el-menu-item index="delicacy" @click="goReqsByType('delicacy')">
									<i class="el-icon-foot"></i>
									<span slot="title">{{$t('basic.delicacy')}}</span>
								</el-menu-item>
								<el-menu-item index="3" @click="goReqsByType('recruit')">
									<i class="el-icon-zhaopin"></i>
									<span slot="title">{{$t('basic.recruit')}}</span>
								</el-menu-item>
								<el-menu-item index="houseproperty" @click="goReqsByType('houseproperty')">
									<i class="el-icon-house2"></i>
									<span slot="title">{{$t('basic.houseproperty')}}</span>
								</el-menu-item>
								<el-menu-item index="locomotive" @click="goReqsByType('locomotive')">
									<i class="el-icon-car"></i>
									<span slot="title">{{$t('basic.locomotive')}}</span>
								</el-menu-item>
								
								<el-menu-item index="flightcargo" @click="goReqsByType('flightcargo')">
									<i class="el-icon-daihuo"></i>
									<span slot="title">{{$t('basic.flightcargo')}}</span>
								</el-menu-item>
								<el-menu-item index="markdownsale" @click="goReqsByType('markdownsale')">
									<i class="el-icon-qingcang"></i>
									<span slot="title">{{$t('basic.markdownsale')}}</span>
								</el-menu-item>
								<el-menu-item index="freeride" @click="goReqsByType('freeride')">
									<i class="el-icon-shunfengche"></i>
									<span slot="title">{{$t('basic.freeride')}}</span>
								</el-menu-item>

								<el-menu-item index="tourist" @click="goReqsByType('tourist')">
									<i class="el-icon-luyou"></i>
									<span slot="title">{{$t('basic.tourist')}}</span>
								</el-menu-item>
								<el-menu-item index="11" @click="goReqsByType('interact')">
									<i class="el-icon-interact"></i>
									<span slot="title">{{$t('basic.interact')}}</span>
								</el-menu-item>
								<el-menu-item index="activity" @click="goReqsByType('activity')">
									<i class="el-icon-huodong"></i>
									<span slot="title">{{$t('basic.activity')}}</span>
								</el-menu-item>
								<el-menu-item index="bigbrand" @click="goReqsByType('discounts')">
									<i class="el-icon-tejia"></i>
									<span slot="title">{{$t('basic.discounts')}}</span>
								</el-menu-item>
	
								<el-menu-item index="roast" @click="goReqsByType('roast')">
									<i class="el-icon-roast"></i>
									<span slot="title">{{$t('basic.busoppo')}}</span>
								</el-menu-item>
								<el-menu-item index="tel" @click="goReqsByType('tel')">
									<i class="el-icon-tel"></i>
									<span slot="title">{{$t('basic.tel')}}</span>
								</el-menu-item>
								<el-menu-item index="rate" @click="goReqsByType('rate')">
									<i class="el-icon-rate"></i>
									<span slot="title">{{$t('basic.rate')}}</span>
								</el-menu-item>
							</template>
							<template v-else>
								<el-menu-item index="personCenter" @click="goToDetail('personCenter')">
									<i class="el-icon-personCt"></i>
									<span slot="title">个人中心</span>
								</el-menu-item>
								<el-menu-item index="myreqs" @click="goToDetail('myreqs')">
									<i class="el-icon-publish"></i>
									<span slot="title">我的发布</span>
								</el-menu-item>
								<el-menu-item index="myPlaceOrders" @click="goToDetail('myPlaceOrders')">
									<i class="el-icon-saleIn"></i>
									<span slot="title">我买的</span>
								</el-menu-item>
								<el-menu-item index="myReceiptOrders" @click="goToDetail('myReceiptOrders')">
									<i class="el-icon-saleOut"></i>
									<span slot="title">我卖出的</span>
								</el-menu-item>
								<el-menu-item index="myRecommendList" @click="goToDetail('/my/recommendList')">
									<i class="el-icon-hot"></i>
									<span slot="title">热点推荐</span>
								</el-menu-item>
								<el-menu-item index="myStoreRecommendList" @click="goToDetail('/my/storeRecommendList')">
									<i class="el-icon-store"></i>
									<span slot="title">企业自荐</span>
								</el-menu-item>
								<el-menu-item index="myStoreRecommendList" @click="goToDetail('/my/advertisements')">
									<i class="el-icon-ad"></i>
									<span slot="title">我的广告</span>
								</el-menu-item>
								<!-- <el-submenu index="adminstra" v-if="isYuweiManager">
									<template slot="title">
										<i class="el-icon-yw"></i>
										<span slot="title">运维中心</span>
									</template>
									<el-menu-item index="2-1" @click="goToDetail('txManager')">提现管理</el-menu-item>
									<el-menu-item index="2-2" @click="goToDetail('certification')">实名管理</el-menu-item>
									<el-menu-item index="2-3" @click="goToDetail('tips')">举报审核</el-menu-item>
									<el-menu-item index="2-4" @click="goToDetail('reviewcase')">内容复审</el-menu-item>
									<el-menu-item index="2-5" @click="goToDetail('proposals')">意见建议</el-menu-item>
									<el-menu-item index="2-6" @click="goToDetail('advertisement')">广告管理</el-menu-item>
								</el-submenu> -->
								<!-- 								<el-menu-item index="wallet" @click="goToDetail('wallet')">
									<i class="el-icon-wallent"></i>
									<span slot="title">我的钱包</span>
								</el-menu-item>
								<el-menu-item index="myAcquiring" @click="goToDetail('myAcquiring')">
									<i class="el-icon-sm"></i>
									<span slot="title">实名认证</span>
								</el-menu-item>
								<el-menu-item index="myAcquiring" @click="goToDetail('myAcquiring')">
									<i class="el-icon-xydb"></i>
									<span slot="title">信用担保</span>
								</el-menu-item>
								<el-menu-item index="proposal" @click="goToDetail('proposal')">
									<i class="el-icon-yijian2"></i>
									<span slot="title">意见建议</span>
								</el-menu-item> -->
							</template>
						</el-menu>
					</div>
				</div>
			</div>
		</div>

		<div class="content_container">
			<div class="nav_warp">
				<div class="tl_header_nav_container">
					<div class="flex-column-start">
						<div class="flex-center">
							<div class="hrb">
								<!-- <div class="icon"></div> -->
								<div class="hrb_txt">{{fayanboAreaName}}</div>
								<div class="change_warp" @click="switchLocal">
									<div class="icon"></div>
									<!-- <div class="txt">切换区域</div> -->
								</div>
							</div>
							<div class="search-warp">
								<el-input placeholder="请输入内容" v-model="input3" class="input-with-select">
									<div slot="append">
										<el-button icon="el-icon-search" @click="searchConts()"></el-button>
									</div>
								</el-input>
							</div>
						</div>
						<!-- <div>中东最大华人同城信息中文平台</div> -->
					</div>
					
					<div class="flex-start">
						<div class="flex-start">
							<div class="flex-center wc-itm" @click="showLogin">
								<div class="el-icon-user-solid margr4"></div>
								<div v-if="!userInfo.fayanboId">{{$t('basic.login')}} / {{$t('basic.register')}}</div>
								<div v-else>{{userInfo.nickName}}</div>
							</div>
							<div class="flex-center wc-itm" @click="openWorkSpace()">
								<div class="margr4 el-icon-s-platform"></div>
								<div>{{$t('basic.workCenter')}}</div>
							</div>
							<div class="flex-center wc-itm" @click="openWindow('','我的消息',800,600)">
								<div class="margr4 icon-chat_msg"></div>
								<div>{{$t('basic.messages')}}</div>
							</div>
						</div>
						<div class="tl_derive"></div>
						<el-popover
						  placement="bottom"
						  width="200"
						  trigger="click">
						  <div class="qrcode_warp">
							  <div class="it_warp">
									<div class="kf_qrcode"></div>
									<div class="margt10">扫码联系客服</div>
							  </div>
						  </div>
						  <div slot="reference" class="t1_header_item_tb custome"></div>
						</el-popover>
						
						<el-popover
						  placement="bottom"
						  width="400"
						  trigger="hover">
						  <div class="qrcode_warp">
							  <div class="it_warp margr20">
									<div class="xcx_qrcode"></div>
									<div class="margt10">扫码关注中东华人宝小程序</div>
							  </div>
							  <div class="it_warp">
									<div class="gzh_qrcode"></div>
									<div class="margt10">扫码关注中东华人宝公共号</div>
							  </div>
							  
						  </div>
						  <div slot="reference" class="t1_header_item_tb phone"></div>
						</el-popover>
						<!-- <div class="en_cn_warp" @click="changeEnZh()">
							<div class="encn_it" v-if="_i18n.locale == 'zh'">
								<svg t="1686046595291" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3095" width="200" height="200"><path d="M573.44 481.0752h-8.8064l33.9968 188.928c22.3232-7.8848 42.8032-19.968 60.928-35.84-18.6368-22.3232-33.4848-47.5136-45.1584-74.0352l35.84-4.608c9.728 19.968 20.992 38.1952 33.9968 53.5552 26.5216-32.1536 46.592-74.9568 60.928-128.512L573.44 481.0752z m135.4752 153.088c20.48 17.7152 43.7248 30.72 69.8368 38.6048l16.2816 5.12-9.728 34.9184-16.2816-5.12c-31.232-9.728-60.0064-26.112-84.6848-47.9232-22.8352 20.48-49.7664 36.352-79.1552 46.08l22.8352 127.488H492.032l-18.1248 83.3536h426.8032c19.968 0 36.352-16.2816 36.352-36.352V263.168c0-19.968-16.2816-36.352-36.352-36.352H519.4752L547.84 385.024l-0.9216-0.512 3.2768 17.2032 0.512-2.3552 7.8848 45.1584h87.9616v-36.352h67.9936v36.352h113.5616v36.352h-47.5136c-15.872 63.8976-40.0384 115.0976-71.68 153.2928zM454.7584 832.9216H123.8016C83.7632 832.9216 51.2 800.3584 51.2 760.32V143.5648c0-40.448 32.5632-72.6016 72.6016-72.6016h363.008l22.3232 119.6032H900.096c40.0384 0 72.6016 32.5632 72.6016 72.6016v617.1648c0 40.0384-32.5632 72.6016-72.6016 72.6016H428.6464l26.112-120.0128zM283.4432 553.6768v-38.1952h-71.68v-56.32h66.56v-37.6832h-66.56v-47.9232h71.68V335.872H169.8816v217.8048h113.5616z m175.0016 0V444.7232c0-19.5584-4.608-34.9184-13.5168-45.568s-22.3232-15.872-40.0384-15.872c-10.24 0-19.5584 1.8432-27.4432 6.0416-7.8848 4.1984-14.4384 10.752-18.6368 18.6368h-2.3552l-5.632-21.4016h-31.6416v167.1168h40.96v-78.6432c0-19.5584 2.7648-33.4848 7.8848-42.3936 5.12-8.3968 13.5168-12.5952 25.088-12.5952 8.3968 0 14.4384 2.7648 18.6368 8.8064 3.6864 6.0416 6.0416 14.848 6.0416 27.0336v97.792h40.6528z" fill="#a13e09" p-id="3096"></path></svg>
							</div>
							<div class="encn_it" v-else>
								<svg t="1686046570460" class="icon" viewBox="0 0 1070 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2898" width="200" height="200"><path d="M232.582 358.133c12.288 36.33 32.59 67.851 60.905 95.633 24.042-26.18 42.207-58.235 53.96-95.633H232.583z" p-id="2899" fill="#a13e09"></path><path d="M981.615 143.36H532.836L507.192 6.055H90.468C44.522 6.055 7.123 43.453 7.123 89.4v708.43c0 45.946 37.399 83.344 83.345 83.344h379.86l-30.453 137.305h541.74c45.947 0 83.345-37.398 83.345-83.344v-708.43c0-45.947-37.398-83.345-83.345-83.345zM415.833 564.358c-49.152-18.165-89.756-41.139-122.346-67.852-34.192 30.453-76.933 52.892-126.62 66.783l-17.096-28.316c48.618-12.822 89.222-32.055 121.277-59.303-33.124-33.658-56.097-72.66-68.92-117.003h-46.48v-32.056h121.277c-7.48-13.89-17.096-27.247-28.316-40.07l32.056-11.753c11.22 14.425 21.37 31.522 30.453 51.29h115.935v32.055h-46.481c-14.96 45.946-36.33 84.413-64.646 115.4 31.522 25.11 71.057 45.947 117.538 63.043l-17.631 27.782zM1023.288 934.6c0 22.974-18.7 41.673-41.673 41.673H492.232l20.837-95.633h156.538l-89.222-497.397-0.534 2.671-3.74-19.767 1.069 0.534-32.59-181.649h437.56c22.973 0 41.672 18.7 41.672 41.673V934.6z" p-id="2900" fill="#a13e09"></path><path d="M684.566 541.384h114.866v-30.453H684.566v-60.905h122.346v-30.453H648.771V638.62h162.95v-30.453H684.565v-66.783zM924.45 475.67c-9.616 0-18.164 1.603-26.178 5.877-7.48 3.74-14.96 9.617-20.837 17.096v-18.699h-34.727V638.62h34.727v-95.633c1.069-12.822 5.343-22.439 12.823-29.384 6.41-5.877 13.89-9.083 22.439-9.083 24.041 0 35.795 12.823 35.795 39.001v94.565h34.727v-97.77c1.069-43.275-19.233-64.646-58.769-64.646z" p-id="2901" fill="#a13e09"></path></svg>
							</div>
						</div> -->
					</div>
					
				</div>
			</div>

			<div class="container-rt">
				<keep-alive>
					<router-view :key="$route.fullPath" v-if="$route.meta.KeepAlive"/>
				</keep-alive>
				<router-view :key="$route.fullPath" v-if="!$route.meta.KeepAlive"/>
			</div>

			<div class="container-rfoot">
				<div>上海海远心洁科技有限公司</div>
				<div>地址：上海市长宁区天山路18号901室</div>
				<div class="flex-center">
					<a target="_blank" href=" "
						style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
						<img src="./assets/icon/gongan.jpg" style="float:left;" />
						<p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">沪公网安备
							31010502006501号</p>
					</a>
					<a style="margin-left: 20px;"><label class="link" @click="showICP()">ICP证：沪B2-20220981</label>  | <label class="link" @click="goGov()">沪ICP备2022026586号</label></a>
				</div>
			</div>
		</div>

		<div class="pers-drawer">
			<el-drawer title="" size="30%" :visible="workcenterdrawer" :with-header="false" @close="handledrawerClose"
				direction="rtl">
				<personCenter ref="pct"></personCenter>
			</el-drawer>
		</div>

		<el-dialog title="微信登录" :visible="loginDialog" @close="handledialogClose" width="40%">
			<wxLogin ref="wxLoginChild" :baseUrlpath="baseUrlpath"></wxLogin>
		</el-dialog>
		<el-dialog title="" :visible="advertiseDialog" @close="handleadvertiseClose" width="60%">
			<submitAdvertise :baseUrlpath="baseUrlpath"></submitAdvertise>
		</el-dialog>
		<el-dialog title="切换区域" :visible="switchLocalDialog" @close="handleSwitchdialogClose" width="40%">
			<div class="choose_warp">
				<div :class="[{'active':fayanboAreaId == '1'},'choose_item']" @click="chooseAreaModel('1','欧洲FAYANBO')">欧洲FAYANBO</div>
				<div :class="[{'active':fayanboAreaId == '5'},'choose_item']" @click="chooseAreaModel('5','中东FAYANBO')">中东FAYANBO</div>
				<div :class="[{'active':fayanboAreaId == '8'},'choose_item']" @click="chooseAreaModel('8','中亚FAYANBO')">中亚FAYANBO</div>
				<div :class="[{'active':fayanboAreaId == '2'},'choose_item']" @click="chooseAreaModel('2','澳新FAYANBO')">澳新FAYANBO</div>
				<div :class="[{'active':fayanboAreaId == '7'},'choose_item']" @click="chooseAreaModel('7','日韩FAYANBO')">日韩FAYANBO</div>
				<div :class="[{'active':fayanboAreaId == '6'},'choose_item']" @click="chooseAreaModel('6','东南亚FAYANBO')">东南亚FAYANBO</div>
				<div :class="[{'active':fayanboAreaId == '3'},'choose_item']" @click="chooseAreaModel('3','美洲FAYANBO')">美洲FAYANBO</div>
				<div :class="[{'active':fayanboAreaId == '4'},'choose_item']" @click="chooseAreaModel('4','非洲FAYANBO')">非洲FAYANBO</div>
			</div>
		</el-dialog>
		<div class="fiex_warp">
			<div class="item_warp" @click="goPublish">
				<div class="icon_publish"></div>
				<div class="txt">免费发布</div>
			</div>
			<div class="item_warp" @click="pushadvertise">
				<div class="icon_ad"></div>
				<div class="txt">广告投放</div>
			</div>
			<div class="item_warp" @click="goHelp">
				<div class="icon_help"></div>
				<div class="txt">常见问题</div>
			</div>
			<el-popover
			  placement="left"
			  width="400"
			  trigger="hover">
			  <div class="qrcode_warp">
				  <div class="it_warp margr20">
						<div class="xcx_qrcode"></div>
						<div class="margt10">扫码关注中东华人宝小程序</div>
				  </div>
				  <div class="it_warp">
						<div class="gzh_qrcode"></div>
						<div class="margt10">扫码关注中东华人宝公共号</div>
				  </div>
			  </div>
			  <div class="item_warp" slot="reference">
			  	<div class="icon_smartPhone"></div>
			  	<div class="txt">手机版</div>
			  </div>
			</el-popover>

		</div>
	</div>

</template>

<script>
	import {
		queryUrl,
		doPost
	} from '@/api/index';
	import {
		Toast
	} from 'mint-ui';
	import personCenter from '@/components/personCenter.vue'
	import wxLogin from '@/components/wxLogin.vue'
	import submitAdvertise from '@/components/submitAdvertise'
	import {
		mapGetters,
		mapActions
	} from 'vuex'
	export default {
		components: {
			personCenter,
			wxLogin,
			submitAdvertise
		},
		data() {
			return {
				isCollapse: false,
				currentPage1: 1,
				tableData: [],
				total: 0,
				pageSize: 10,
				input3: '',
				isWorkCenter: false,
				baseUrlpath: '',
				switchLocalDialog:false,
				fayanboAreaId:'5',
				fayanboAreaName:'中东FAYANBO'
			};
		},
		beforeCreate() {
			console.log('beforeCreate')
		},
		created() {
			console.log('appcreated');
			this.baseUrlpath = this.baseUrl;
			this.$store.dispatch('initModel', {});
			if(localStorage.getItem("fayanboAreaId")){
				this.fayanboAreaId = localStorage.getItem("fayanboAreaId");
				this.fayanboAreaName = localStorage.getItem("fayanboAreaName");
			}else{
				localStorage.setItem('fayanboAreaId',this.fayanboAreaId)
				localStorage.setItem('fayanboAreaName',this.fayanboAreaName)
			}
			this.getCityList()
			this.initUserInfo();
			//this.query();
		},
		mounted() {
			console.log('appmounted')
			this.getCityList()
		},
		computed: {
			...mapGetters(['workcenterdrawer', 'loginDialog', 'userInfo', 'menuIndexstr', 'advertiseDialog','isYuweiManager'])
		},
		methods: {
			chooseAreaModel(areaId,areaName){
				this.fayanboAreaId = areaId;
				this.fayanboAreaName = areaName;
				localStorage.setItem('fayanboAreaId',this.fayanboAreaId)
				localStorage.setItem('fayanboAreaName',this.fayanboAreaName)
				this.switchLocalDialog = false
				window.location.reload()
			},
			switchLocal(){
				this.switchLocalDialog = true
			},
			handleSwitchdialogClose(){
				this.switchLocalDialog = false
			},
			changeEnZh(e) {
				if (this._i18n.locale == 'zh') {
					//英文
					this._i18n.locale = 'en';
					sessionStorage.setItem('lang', 'en');
				} else {
					//中文
					this._i18n.locale = 'zh';
					sessionStorage.setItem('lang', 'zh');
				}
			},
			goGov(){
				window.open('https://beian.miit.gov.cn/#/Integrated/index','_blank')
			},
			showICP(){
				window.open('https://www.fayanbo.com/images/img-Y16090013-0002.pdf','_blank')
			},
			searchConts(){
				if(this.input3.length == 0){
					this.$util.warnMsg('请收入搜索内容');
					return false
				}
				var searchConts = this.input3;
				this.$router.push({
					name: '/req/search',
					query: {
						searchConts: searchConts
					}
				});
			},
			goPublish(){
				this.$router.push({
					name: 'publishIndex'
				});
			},
			pushadvertise(){
				if (!this.userInfo.fayanboId) {
					this.$util.warnMsg("请登录系统！")
					this.$store.dispatch('setLoginDialogvisible', true)
				} else {
					this.$router.push({
						name: '/my/advertisements'
					});
				}
			},
			goHelp(){
				this.$router.push({
					name: '/help'
				});
			},
			async initUserInfo() {
				// console.log("初始initUserInfo")
				var accesstoken;
				//var openId = 'oajAN5jeUZuiE40g5ZRwr433fk5w'
				//accesstoken ="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1bmlvbklkIjoibzlHeC01OEFuRkhOc0VacWZFWTRkczB5MGxtYyIsIm9wZW5JZCI6Im9hakFONWplVVp1aUU0MGc1WlJ3cjQzM2ZrNXciLCJmYXlhbmJvSWQiOiI0NzA2NTUwNSIsImV4cCI6MTY5OTcwNDc4MiwiaWF0IjoxNjk5MDk5OTgyfQ.Fo8Ww2Vs34r_wZFJuXozS55D7djcUxJmbjLwhXUBVOM";
				//localStorage.setItem('accesstoken', accesstoken);
				if (localStorage.getItem('accesstoken')) {
					accesstoken = localStorage.getItem('accesstoken');
				}
				if (accesstoken) {
					var res = await queryUrl("/apis/getUserInfo", {});
					// console.log(res)
					if (res.ec == '0') {
						var userInfo = {
							avatarUrl: res.avatarUrl,
							nickName: res.nickName,
							vipEndTime: res.sdu_vipEndTime,
							fayanboId: res.sdu_userId,
							openId: res.sdu_openId,
							mls:res.mls
						};
						this.$store.commit('setStateAttr', {
							key: 'userInfo',
							val: userInfo
						})
						localStorage.setItem('userInfo', JSON.stringify(userInfo))
						this.$store.dispatch('setLoginDialogvisible', false)
						// console.log(this)
					}
				}
			},
			handledrawerClose(e) {
				this.$store.dispatch('setWorkcenterdrawer', false)
			},
			handledialogClose(e) {
				//this.$refs.wxLoginChild.clearQrcodeInterval()
				this.$store.dispatch('setLoginDialogvisible', false)
			},
			handleadvertiseClose(e) {
				this.$store.dispatch('setAdvertiseDialogvisible', false)
			},
			showLogin() {
				if (!this.userInfo.fayanboId) {
					this.$store.dispatch('setLoginDialogvisible', true)
				} else {
					this.$store.dispatch('setWorkcenterdrawer', true)
				}
			},
			openWorkSpace() {
				if (!this.userInfo.fayanboId) {
					this.$store.dispatch('setLoginDialogvisible', true)
				} else {
					this.$util.goto('myreqs')
				}

			},
			query() {
				var vm = this;
				var params = {};
				params.currentPage = vm.currentPage1;
				params.pageSize = vm.pageSize;
				params.areaId = "['all','all']";
				queryUrl("/apis/getReqs", params).then(res => {
					vm.tableData = res.reList;
					vm.total = res.totalNum;
				});
			},
			getCityList(){
				var vm = this;
				var params = {};
				if(localStorage.getItem("fayanboAreaId")){
					params.modelId = localStorage.getItem("fayanboAreaId")
				}
				this.baseUrlpath = this.baseUrl;
				var inchina = localStorage.getItem('inChina');
				var requestUrl = '/apis/getCityList'
				if(inchina == '1'){
					requestUrl = '/cnapis/getCityList'
				}
				queryUrl(requestUrl, params).then(res => {
					console.log('城市列表',res)
					if( res.list.length > 0){
						vm.$util.citysList = res.list
					}else{
						vm.$util.citysList = vm.$util.zdcitysList
					}
				});
			},
			changeCollapse() {
				this.isCollapse = !this.isCollapse
			},
			handleOpen(key, keyPath) {
				// console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				// console.log(key, keyPath);
			},
			goToDetail(url) {
				this.$router.push({
					name: url
				});
			},
			goReqsByType(reqType) {
				this.$router.push({
					name: reqType,
				});
			},
			talktoCoustom(){
				var accesstoken = localStorage.getItem('accesstoken');
				if (!accesstoken) {
					this.$util.warnMsg('请先登录系统');
					return false
				}
				var url = 'https://www.fayanbo.com/chat/#/message?token=' + accesstoken + '&fayanboId=' + this.userInfo
					.fayanboId + '&tId=' + '47065505';
				this.openWindow(url, '我的消息', 1000, 1000)
			},
			openWindow(url, title, w, h) {
				var accesstoken = localStorage.getItem('accesstoken');
				if(!accesstoken){
					this.$util.warnMsg('请先登录系统');
					return false
				}
				url = 'https://www.fayanbo.com/chat/#/message?token='+accesstoken+'&fayanboId='+this.userInfo.fayanboId;
				const dualScreenLeft =
					window.screenLeft !== undefined ? window.screenLeft : screen.left;
				const dualScreenTop =
					window.screenTop !== undefined ? window.screenTop : screen.top;

				const width = window.innerWidth ?
					window.innerWidth :
					document.documentElement.clientWidth ?
					document.documentElement.clientWidth :
					screen.width;
				const height = window.innerHeight ?
					window.innerHeight :
					document.documentElement.clientHeight ?
					document.documentElement.clientHeight :
					screen.height;

				const left = width / 2 - w / 2 + dualScreenLeft;
				const top = height / 2 - h / 2 + dualScreenTop;
				const newWindow = window.open(
					url,
					title,
					"toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
					//"toolbar=yes, location=yes, directories=yes, status=yes, menubar=yes, scrollbars=yes, resizable=yes, copyhistory=yes, width=" +
					w +
					", height=" +
					h +
					", top=" +
					top +
					", left=" +
					left
				);

				if (window.focus) {
					newWindow.focus();
				}
				
				newWindow.location.reload(true)
			}

		},
		watch: {
			$route: {
				handler: function(nval, oval) {
					console.log(nval, oval)
					if (oval == undefined) {

					}
					if (nval.meta && nval.meta.isMenu) {
						if (nval.meta.isWorkCenter) {
							this.isWorkCenter = true
						} else {
							this.isWorkCenter = false
						}
						this.$store.dispatch('setMenuIndexstr', nval.name)
						// console.log(this.menuIndexstr)
					}
				},
				deep: true,
				immediate: true
			}
		}
	}
</script>

<style lang="scss">
	.headwarp {
		.head-item {
			font-size: 18px !important;
		}
	}
	.ctx-item .ctx-item-t .ctx-item-head {
		font-size: 18px !important;
	}
	.choose_warp {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		align-items: center;
		.choose_item {
			font-size: 24px;
			font-weight: 700;
			width: 33.33%;
			height: 120px;
			line-height: 120px;
			cursor: pointer;
			box-sizing: border-box;
		}
		.active {
			border: #a13e09 2px solid;
			border-radius: 10px;
			color: #a13e09;
		}
		.choose_item:hover {
			background-color: #a13e09;
			color: #FFFFFF;
			border-radius: 6px;
		}
	}
	.hrb {
		display: flex;
		.hrb_txt {
			font-size: 34px;
			font-weight: 700;
			color: #a13e09;
		}
		.change_warp {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			font-size: 12px;
			margin-left: 12px;
			font-weight: 700;
			cursor: pointer;
			.icon {
				background: url(../src/assets/icon/swithLocal.png) no-repeat center;
				background-size: 100%;
				height: 26px;
				width: 26px;
			}
			.txt {
				margin-top: 2px;
			}
		}
	}

	.en_cn_warp {
		margin-left: 10px;
		cursor: pointer;
		.encn_it {
			width: 30px;
			height: 30px;
			svg {
				width: 100%;
				height: 100%;
			}
		}
	}
	.fiex_warp {
		z-index: 9999999;
		position: fixed;
		right: 4px;
		bottom: 55px;
		color: #000000;
		box-shadow: 0px 0px 16px 0px rgb(39 34 34 / 51%);
		border-radius: 20px;
		.item_warp {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;			
			cursor: pointer;
			padding: 6px 0px;
			width: 60px;
			height: 60px;
			.icon_publish {
				background: url(./assets/icon/publish.png) no-repeat center;
				background-size: 100%;
				height: 30px;
				width: 30px;
			}
			.icon_ad {
				background: url(./assets/icon/ad.png) no-repeat center;
				background-size: 100%;
				height: 30px;
				width: 30px;
			}
			.icon_help {
				background: url(./assets/icon/help.png) no-repeat center;
				background-size: 100%;
				height: 30px;
				width: 30px;
			}
			.icon_smartPhone {
				background: url(./assets/icon/smartPhone.png) no-repeat center;
				background-size: 100%;
				height: 30px;
				width: 30px;
			}
			.txt {
				font-size: 12px;
				font-weight: 700;
				margin-top: 4px;
			}
		}
	}
	
	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		display: flex;
	}

	html,
	body {
		position: relative;
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		background: #fff;
	}
	
	.req_tips {
		color: #ff2424fc;
		font-size: 18px;
		padding: 0px;
		line-height: 30px;
		text-align: left;
		padding: 10px 0;
	}
	
	.qrcode_warp {
		display: flex;
		justify-content: space-around;
		margin: 20px;
		font-size: 13px;
		.it_warp {
			display: flex;
			flex-direction: column;
			text-align: center;
			justify-content: center;
			align-items: center;
		}
		.gzh_qrcode {
			background: url(./assets/icon/qrcode_for_gh_9696e0cc0088_344.jpg) center no-repeat;
			background-size: 100%;
			width: 140px;
			height: 140px;
		}
	
		.xcx_qrcode {
			background: url(./assets/icon/gh_456f22a2f958_344.jpg) center no-repeat;
			background-size: 100%;
			width: 140px;
			height: 140px;
		}
	
		.kf_qrcode {
			background: url(./assets/icon/kf_qrcode.png) center no-repeat;
			background-size: 100%;
			width: 140px;
			height: 140px;
		}
	
		.app_qrcode {
			background: url(./assets/icon/JSONS_637976590453355845.png) center no-repeat;
			background-size: 100%;
			width: 140px;
			height: 140px;
		}
	}
	.manager_btn_popper {
		.pop_btn_warp {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.item_btn {
				line-height: 34px;
				width: 100px;
				cursor: pointer;
				text-align: center;
			}
			.item_btn:hover {
				color: #C88854;
				font-weight: 700;
			}
			.active {
				line-height: 34px;
				width: 100px;
				text-align: center;
				color: #D8D8D8;;
			}
		}
		.nomal_btn {
			background-color: #FFFFFF;
			color: #C88854;
			padding: 6px 24px;
			border-radius: 20px;
			font-size: 14px;
			cursor: pointer;
			border: 1px solid;
		}
	}

	.fixed_msg {
		position: fixed;
		bottom: 50px;
		right: 50px;
		height: 100px;
		width: 100px;
		cursor: pointer;
	}
	
	.nav_warp {
		padding: 0 20px;
	}

	.tl_header_nav_container {
		width: 100%;
		margin: 0 auto;
		height: 72px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		.tl_derive {
			height: 20px;
			width: 1px;
			background-color: #dadfe6;
			margin-left: 12px;
		}
		.t1_header_item_tb {
			margin-left: 12px;
			cursor: pointer;
		}
		.phone {
			background: url(assets/icon/phone.png) no-repeat center;
			background-size: 100%;
			width: 22px;
			height: 22px;
		}
		.custome {
			background: url(assets/icon/custom_serve.png) no-repeat center;
			background-size: 100%;
			width: 22px;
			height: 22px;
		}
	}

	.tl_header_nav_container .logo {
		font-family: fantasy;
		font-weight: 700;
		font-size: 30px;
		color: #a13e09;
		display: flex;
		.icon {
			background: url(assets/icon/fayanbo.png) no-repeat center;
			background-size: 220%;
			height: 40px;
			width: 160px;
		}
		.logo_txt {
			//width: 154px;
		}
	}

	.tl_header_nav_container .search-warp {
		margin-left: 20px;
		width: 260px;
	}

	.tl_header_nav_container .wc-itm {
		//margin-right: 14px;
		cursor: pointer;
		min-width: 100px;
		margin-left: 10px;
		font-size: 18px;
	}

	.search-warp .el-input-group__append {
		background-color: #c88854;
		border: 1px solid #c88854;
	}

	.search-warp .el-input__inner {
		border: 1px solid #c88854;
		height: 32px;
		line-height: 32px;
	}

	.el-icon-search::before {
		color: #FFFFFF;
	}

	.margr20 {
		margin-right: 20px;
	}

	.margr10 {
		margin-right: 10px;
	}

	.margl4 {
		margin-left: 4px;
	}

	.margr4 {
		margin-right: 4px;
	}

	.margt6 {
		margin-top: 6px;
	}
	
	.margb8 {
		margin-bottom: 8px;
	}

	.margt10 {
		margin-top: 10px;
	}

	.margt14 {
		margin-top: 14px;
	}
	
	.margt16 {
		margin-top: 16px;
	}

	.margb14 {
		margin-bottom: 14px;
	}
	
	.mint-toast {
		z-index: 99999;
	}

	.hp_header {
		margin: 0;
		padding: 0;
		background: #fff;
		border-right: 1px solid #DADFE6;
	}

	.leftSideNavLayer {}

	.lsn_top_button_wrap {
		display: flex;
		justify-content: flex-start;
	}

	.lsn_left_nav_container {
		color: #555;
		font-size: 14px;
		font-family: Pingfang SC, Helvetica, Helvetica Neue, Microsoft YaHei, Arial, Heiti SC, sans-serif;
		background: #fff;
		height: 100%;
	}

	.lsn_small_left_nav_container {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.lsn_small_left_nav_container {
		.el-menu {
			.el-menu-item {
				text-align: left;
				span {
					display: inline-block;
					white-space: normal;
					line-height: 22px;
					word-break: break-word;
					width: 120px;
					font-size: 18px;
				}
			}
		}
	}

	.content_container {
		flex: 1;

		.container-rt {
			min-height: 100vh;
		}

		.container-rfoot {
			margin: 0 auto;
			padding-top: 40px;
			padding-bottom: 52px;
			background: #f8fafd;
			color: #999;
			font-size: 12px;
			line-height: 23px;
			.link {
				cursor: pointer;
			}
			.link:hover {
				color: #0783ef;
			}
		}
	}

	.pc_home-tabbtnIcon {
		width: 24px;
		font-size: 24px;
		padding-left: 8px;
	}

	.lsn_top_button {
		cursor: pointer;
		padding: 12px;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 180px;
		min-height: 400px;
	}

	.el-icon-main {
		background: url(assets/icon/main.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-daihuo {
		background: url(assets/icon/daihuo.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-shunfengche {
		background: url(assets/icon/shunfengche.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-tejia {
		background: url(assets/icon/sale.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-qingcang {
		background: url(assets/icon/Idle.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-huodong {
		background: url(assets/icon/huodong.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-car {
		background: url(assets/icon/car.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-house2 {
		background: url(assets/icon/house.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-luyou {
		background: url(assets/icon/luyou.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-zhaopin {
		background: url(assets/icon/zhaopin.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-foot {
		background: url(assets/icon/foot2.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-interact {
		background: url(assets/icon/interact.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-roast {
		background: url(assets/icon/bussniss.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-tel {
		background: url(assets/icon/tel.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-rate {
		background: url(assets/icon/rate.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.icon-chat_msg {
		background: url(assets/icon/chat_msg.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-publish {
		background: url(assets/icon/icon_publish.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-personCt {
		background: url(assets/icon/personCt.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-yw {
		background: url(assets/icon/yunwei.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-saleIn {
		background: url(assets/icon/buyin.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-saleOut {
		background: url(assets/icon/saleOut.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-hot {
		background: url(assets/icon/hot.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}
	
	.el-icon-store {
		background: url(assets/icon/store.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}
	
	.el-icon-ad {
		background: url(assets/icon/ad.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-wallent {
		background: url(assets/icon/wallent.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-sm {
		background: url(assets/icon/sm2.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-xydb {
		background: url(assets/icon/xydb.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.el-icon-yijian2 {
		background: url(assets/icon/yijian2.png) center no-repeat;
		background-size: 100%;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.men-tit {
		font-family: PingFang SC;
		font-style: normal;
		font-size: 16px;
		font-weight: 500;
		line-height: 22px;
		margin-right: 4px;
		color: #333333;
	}

	.menu-item {
		font-size: 14px;
		color: #303133;
		padding: 0 13px;
		cursor: pointer;
		transition: border-color .3s, background-color .3s, color .3s;
		box-sizing: border-box;
		height: 56px;
		line-height: 56px;
		position: relative;
	}

	.menu-item-warp {
		background-color: #fff7f0;
		margin: 0 20px;
		border-radius: 20px;
	}

	.flex-warp {
		flex-wrap: wrap;
	}

	.flex-start {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.flex-start-start {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}
	
	.flex-start-baseline {
		display: flex;
		justify-content: flex-start;
		align-items: baseline;
	}

	.flex-end {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.flex-center {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.flex-center-baseline {
		display: flex;
		justify-content: center;
		align-items: baseline;
	}

	.flex-around {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	.flex-around-baseline {
		display: flex;
		justify-content: space-around;
		align-items: baseline;
	}

	.flex-between {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.flex-column-between {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
	}

	.flex-column-center {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.flex-column-start {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-direction: column;
	}

	.flex-column-end {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex-direction: column;
	}

	.flex-column-c-end {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		flex-direction: column;
	}

	.flex-column-a-s {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: flex-start;
	}

	.flexone {
		flex: 1;
	}
	
	.hundred {
		width: 100%;
	}
	
	.el-carousel {
		height: 100% !important;
	}
	
	.el-tabs__item {
		padding: 0 10px !important;
		font-size: 18px !important;
		width: 50%;
		text-align: center;
	}
	
	.el-carousel__container{
	  /*height: 456px;*/
	}

	.el-tabs__item.is-active {
		color: #c88854 !important;
		font-weight: bold !important;
	}

	.el-tabs__active-bar {
		background-color: #c88854 !important;
		;
	}

	.el-radio-group {
		display: flex !important;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
	}
	
	.el-radio-group .el-radio {
		margin-top: 10px;
	}
	
	.el-radio-group .is-checked .el-radio__label {
		color: #C88854 !important;
	}

	.el-radio__input.is-checked .el-radio__inner {
		border-color: #C88854 !important;
		background: #C88854 !important;
	}

	.el-checkbox__label,.el-radio__label {
		font-size: 18px !important;
	}

	.el-checkbox__input.is-checked+.el-checkbox__label {
		color: #C88854 !important;
		font-size: 18px !important;
	}

	.el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: #C88854 !important;
		border-color: #C88854 !important;
		font-size: 18px !important;
	}

	.el-checkbox__input.is-focus .el-checkbox__inner {
		border-color: #C88854 !important;
	}

	.el-cascader-node.in-active-path,
	.el-cascader-node.is-active,
	.el-cascader-node.is-selectable.in-checked-path {
		color: #C88854 !important;
		font-weight: 700 !important;
	}

	.el-select-dropdown__item.selected {
		color: #C88854 !important;
		font-weight: 700 !important;
	}

	.el-range-editor.is-active,
	.el-range-editor.is-active:hover,
	.el-select .el-input.is-focus .el-input__inner {
		border-color: #C88854 !important;
	}

	.el-select .el-input__inner:focus {
		border-color: #C88854 !important;
	}

	.el-cascader .el-input .el-input__inner:focus,
	.el-cascader .el-input.is-focus .el-input__inner {
		border-color: #C88854 !important;
	}

	.el-submenu__title {
		text-align: left;
	}

	.el-submenu .el-menu-item {
		min-width: 150px !important;

	}
	
	.marlr10 {
		margin: 0 10px;
	}
	
	.txt_left {
		text-align: left;
	}
	
	.pub_coin_unit_desc {
		line-height: 20px;
		font-size: 10px;
		text-align: left;
		color: red;
	}
	
	.pub_jd_cost {
		color: #DD524D;
		font-weight: 700;
		background-color: #f4f4f4;
		border-top: 1px solid #e6e6e6;
		padding: 10px 20px;
		.jd_total {
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			.num {
				font-size: 28px;
				font-weight: bold;
				margin: 0 4px;
			}
			.unit {
				font-size: 14px;
				position: relative;
				bottom: 4px;
			}
		}
		
		.cost_desc {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			font-size: 12px;
			color: #A5A5A5;
			.num {
				margin: 0 2px;
			}
		}
	}
	.commend_conts {
		position: relative;
		height: 50rem;
		transform-style: preserve-3d;
		overflow: hidden;
	}
	.commend_conts .cont_body {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		//transform-origin: left;
		background-color: #fff;
		opacity: 0;
	}
	.commend_conts .active {
		z-index: 2;
		animation: flip 1s linear;
		animation-fill-mode: forwards;
		opacity: 1;
	}
	
	@keyframes flip {
		0% {
			transform: rotateY(90deg);
		}
		50% {
			transform: rotateY(45deg);
		}
		100% {
			transform: rotateY(0deg);
		}
	}
</style>
